import Layout from "../../components/layout";
import PageTitle from "../../components/pagetitle";
import Seo from "../../components/seo";
import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";

const EdgewaterPage = () => (
    <Layout>
        <PageTitle title="Managed Services and Software In Edgewater" page="PageTitleAbout" />
        <Seo title="Managed Services and Software In Edgewater Maryland"
             description="New Vertical offers Managed Services and Software services to Edgewater, Maryland - one of the fastest-growing towns in the US - is home to a rapidly-increasing technology industry."
             pathname={"/locations/edgewater/"}/>
        <div className="content-page-wrapper">
            <div className="page-intro">
                <StaticImage
                    style={{
                        gridArea: "1/1",
                    }}
                    layout="fullWidth"
                    loading="eager"
                    aspectRatio={2 / 1}
                    alt="Our team will help"
                    src={
                        "../../images/edgewater.jpg"
                    }
                    formats={["auto", "jpg", "avif"]}
                />
                <p>Edgewater, Maryland - one of the fastest-growing towns in the US - is home to a rapidly-increasing technology services industry. With an array of different businesses providing powerful software solutions and technological advancements, Edgewater has become the place to be for modern technology.</p>

                <p> At the center of Edgewater’s tech landscape is XCon Software Solutions. XCon is a software development firm providing groundbreaking, web-based solutions to companies all across the state. From developing custom applications to providing digital marketing services and creative design services, XCon strives to provide every business with the latest technology to help them stay ahead of the competition.</p>

                <p> One of the most popular tech services provided in Edgewater is the IT consulting services offered by All Systems Go. With a staff of highly skilled computer and network engineers, All Systems Go helps businesses with everything from network security to managed services. From design and installation to troubleshooting and repair, All Systems Go is the go-to source for all of Edgewater’s IT needs.</p>

                <p> Another great tech service in Edgewater comes in the form of Design Dynamics. Design Dynamics offers a wealth of services for businesses in Edgewater, including logo design, website design, and mobile app design. Whether companies need to create a new logo or website from scratch or find help in perfecting an existing one, Design Dynamics is the professional service to call.</p>

                <p> Finally, Edgewater tech services are not complete without mentioning Web Network Solutions (WNS). WNS is a full-scale web design and hosting firm providing businesses around the nation with the latest in web technology and web application development. From website creation and hosting to e-commerce solutions and search engine optimization, WNS helps make sure businesses in Edgewater have the tools they need to increase visibility and results.</p>

                <p> With this incredible array of technology services in Edgewater, businesses can rest assured knowing they will have all of the tools necessary to stay ahead of the competition. Whether they are looking to take their business online or simply need help keeping their IT systems up and running, Edgewater has the perfect tech services to meet every need. For those looking for the latest in tech services, Edgewater is one of the top places to be.</p>
            </div>
        </div>
    </Layout>
)

export default EdgewaterPage